import { PayloadAction } from 'core/utils/actionUtils';
import { ACTION_GET_PROPERTIES_LIST } from '../actions/properties.actions';

export interface PropertiesState {
  properties?: any[];
  loading?: boolean;
  error?: any;
}

const initialState: PropertiesState = {
};

export const propertiesStateReducer = (state: PropertiesState = initialState, action: PayloadAction<any>): PropertiesState => {
  switch (action.type) {
    case ACTION_GET_PROPERTIES_LIST.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ACTION_GET_PROPERTIES_LIST.SUCCESS:
      return {
        ...state,
        loading: false,
        properties: action.payload,
      };
    case ACTION_GET_PROPERTIES_LIST.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
