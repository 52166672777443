import {
  FakePlanetResponseObject,
  PlanetResponseObject,
} from "../interfaces/interfaces";

const NOT_PLANET_REGEX =
  /OK: ([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}) does not have planet/;
const DISABLED_PLANET_REGEX =
  /OK: Planet disabled for geom ([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})/;
const HAS_PLANET_REGEX =
  /OK: ([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}) have planet/;
const ENABLED_PLANET_REGEX =
  /OK: Planet enabled for geom ([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})/;
const ALREADY_ENABLED_PLANET_REGEX =
  /OK: Planet was already enabled for geom ([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})/;

const parseResponse = (
  rawResponseData: PlanetResponseObject[] | FakePlanetResponseObject[]
) => {
  const color = (result: string) => {
    if (
      !!result.match(NOT_PLANET_REGEX) ||
      !!result.match(DISABLED_PLANET_REGEX)
    ) {
      return "#21f336"; // green
    } else if (
      !!result.match(HAS_PLANET_REGEX) ||
      !!result.match(ENABLED_PLANET_REGEX) ||
      !!result.match(ALREADY_ENABLED_PLANET_REGEX)
    ) {
      return "#00b5d8"; // blue
    } else if (!!result.match(/error/i)) {
      return "red";
    } else if (!!result.match(/not valid/i)) {
      // Also return red but it's a different format,
      // To catch these kind of errors, but not trigger mask update
      return "#ff0000";
    } else {
      return "grey";
    }
  };

  const resultMode = (mode: string | undefined) => {
    if (mode === "automatic") {
      return "Auto";
    } else if (mode === "manual") {
      return "Uploaded";
    }
    return undefined;
  };

  return rawResponseData?.map(
    (element: PlanetResponseObject, index: number) => {
      const parsedResponse: any = {
        property_id: element.property_id,
        color: color(element.result),
        isPlanetEnabled: !!element?.result?.match(HAS_PLANET_REGEX),
        isValid: color(element.result) !== "red",
        name: element.name,
        area:
          Math.round(
            (element.geom_info ? element.geom_info.total_area : 0) * 100
          ) / 100,
        vertices: element.geom_info?.n_vertices,
      };

      if (element.field_id) {
        parsedResponse.field_id = element.field_id;
      }

      if (element.simplification_info) {
        const result: any = {
          mode: resultMode(element.simplification_info?.type),
          area: element.simplification_info?.total_area,
          vertices: element.simplification_info?.n_vertices,
          excess: element.simplification_info?.excess,
        };
        parsedResponse.result = result;
      }

      return parsedResponse;
    }
  );
};

export default parseResponse;
