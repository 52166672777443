import { fetchCurrentUser, initOAuth2Flow, logout } from 'app-redux/actions/login.actions';
import { AppState } from 'app-redux/reducers';
import { getCurrentUser, getCurrentUserLoadingStatus } from 'app-redux/selectors/accounts.selector';
import { IUserAccount } from 'core/accounts/models';
import { isAccessTokenAvailable } from 'core/common-methods';
import { history, RoutePaths } from 'core/history';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonType } from 'syngenta-digital-cropwise-react-ui-kit';

interface ILoginSelectionProps {
  currentUser?: IUserAccount;
  fetchCurrentUser?: () => Promise<any>;
  isLoadingUser?: boolean;
  logoutUser?: () => any;
}

const LoginSectionComponent: React.FC<ILoginSelectionProps> = (props) => {
  const handleLoginClick = () => {
    initOAuth2Flow();
  };

  useEffect(() => {
    if (isAccessTokenAvailable() && !props.currentUser && !props.isLoadingUser && props.fetchCurrentUser) {
      props.fetchCurrentUser().then();
    }
  }, []);

  const handleLogoutClick = () => {
    if (props.logoutUser) {
      props.logoutUser();
      history.push(RoutePaths.HOME());
    }
  };

  if (!props.currentUser) {
    return (
            // <Menu theme="light" mode="horizontal">
            //     <Menu.Item onClick={handleLoginClick} key="1">Log in</Menu.Item>
            // </Menu>
            <Button type={ButtonType.default} style={{ marginTop: '10px' }} onClick={handleLoginClick}>
                Log in
            </Button>
    );
  } else {
    return (
          // <Menu theme="light" mode="horizontal">
          //     <Menu.Item onClick={handleLogoutClick} key="1">{props.currentUser.name.charAt(0).toUpperCase()}</Menu.Item>
          // </Menu>
          <Button type={ButtonType.default} style={{ marginTop: '10px' }} onClick={handleLogoutClick}>
                Log out
            </Button>
    );
  }

};

const mapStateToProps = (state: AppState, ownProps: ILoginSelectionProps): ILoginSelectionProps => {
  return {
    ...ownProps,
    currentUser: getCurrentUser(state),
    isLoadingUser: getCurrentUserLoadingStatus(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  logoutUser: () => dispatch(logout()),
});

export const LoginSection = connect(mapStateToProps, mapDispatchToProps)(LoginSectionComponent);
