import "./PlanetSearch.less";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Layout,
  Space,
  Switch,
} from "antd";
import React from "react";
const { Content } = Layout;

import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";

export const PlanetSearchBox: React.FC<any> = ({
  onSubmit,
  onSubmitFailed,
  propertyID,
  setPropertyID,
  fieldBasedEnrollment,
  setFieldBasedEnrollment,
  message,
  checkedListProps,
  setCheckedListProps,
  buttonDisabled,
  buttonText,
  loading,
  selectAll,
  selectionIndeterminate,
  onManuallyChangeSelectAll,
  fetchProps,
}) => {
  return (
    <>
      <Breadcrumb className="cw-app-breadcrumb-container">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Planet Activation</Breadcrumb.Item>
      </Breadcrumb>

      <Content className="cw-app-content-container">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
          autoComplete="off"
          className="sidebar-planet"
        >
          <Form.Item
            label="Property ID"
            name="propertyID"
            rules={[
              { required: true, message: "Please input the property ID!" },
            ]}
          >
            <Input
              placeholder="6f8a3a4a-c18f-455f-84dc-96d52a48b4fa"
              value={propertyID}
              onChange={(e) => {
                setPropertyID(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item>
            <div className="grid-wrapper">
              <div className="grid-child-1">
                <span>Use field based enrollment</span>
              </div>
              <div className="grid-child-2">
                <Switch
                  onChange={setFieldBasedEnrollment}
                  defaultChecked={fieldBasedEnrollment}
                />
              </div>
            </div>
          </Form.Item>

          <Divider />

          <Form.Item name="checkList">
            <div className="checkbox-container">
              {message}
              {Object.values(checkedListProps)?.map(
                (item: any, index: number) => {
                  return (
                    <CustomCheckbox
                      id={item.id}
                      fetchProps={fetchProps}
                      checkedListProps={checkedListProps}
                      setCheckedListProps={(e: any) => setCheckedListProps(e)}
                      key={index}
                    />
                  );
                }
              )}
            </div>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                disabled={buttonDisabled}
              >
                <div className="center-box default-button-width">
                  {loading ? <div className="spinner" /> : buttonText}
                </div>
              </Button>
              <Checkbox
                indeterminate={selectionIndeterminate}
                onChange={onManuallyChangeSelectAll}
                checked={selectAll}
              >
                Select All
              </Checkbox>
            </Space>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
};
