import axios from "axios";
import { getHeaders } from "core/common-methods";
import { createRequestAction, RequestAction } from "core/utils/actionUtils";
import { PlanetRequest } from "pages/api-frontend/interfaces/interfaces";
import { Dispatch } from "redux";

export const ACTION_PLANET_REQUEST: RequestAction = createRequestAction(
  "ACTION_PLANET_REQUEST",
);

const BASE_URL = process.env.PLANET_API_URL;

export const PlanetAction =
  (body: PlanetRequest) => async (dispatch: Dispatch) => {
    try {
      dispatch({ type: ACTION_PLANET_REQUEST.REQUEST });

      const action = await axios.post(`${BASE_URL}/planet`, body, {
        headers: {
          'Content-Type': "application/json",
          ...getHeaders().common,
        },
      });

      dispatch({ type: ACTION_PLANET_REQUEST.SUCCESS, payload: action.data });

      return action.data;
    } catch (e) {
      dispatch({ type: ACTION_PLANET_REQUEST.ERROR, payload: e });
    }
  };
