import { PlanetAction } from "../actions/planet.actions";
import {
  CheckedListPropObject,
  CheckedListProps,
  OnMatchUUIDObject,
  PlanetResponse,
  PlanetResponseHandler,
  SubmitHandlerObject,
  ValidationObject,
} from "../interfaces/interfaces";
import { parseToQuery, parseToRequiredDataObject } from "./queryUtils";

export const createBaseCheckedListProps = (
  checkedList: PlanetResponseHandler[],
  setCheckedListProps: any
) => {
  console.log("entering creating on props");
  const formattedCheckedList = checkedList?.map((item: any, index: number) => {
    const {
      property_id,
      field_id,
      isPlanetEnabled,
      name,
      area,
      vertices,
      result,
      isValid,
      color,
    } = item;

    const id = field_id || property_id;

    return {
      id,
      property_id,
      field_id,
      isCheckboxEnabled: false,
      isPlanetEnabled,
      name,
      area,
      vertices,
      result,
      mask: {},
      isValid,
      color,
    };
  });

  const listToMap = (list: any) =>
    list.reduce(function (map: any, item: any) {
      map[item.id] = item;
      return map;
    }, {});

  setCheckedListProps(listToMap(formattedCheckedList));
};

export const onMatchUUID = async (args: OnMatchUUIDObject) => {
  const {
    setMessage,
    setCheckedListProps,
    setCheckedList,
    propertyID,
    fieldBasedEnrollment,
    dispatch,
    setCheckedListRaw,
  } = args;

  const query = parseToQuery(propertyID, fieldBasedEnrollment);

  dispatch(PlanetAction(query)).then((res: PlanetResponse) => {
    console.log("sent this query", query);
    if (res) {
      const listToMap = (list: any) =>
        list.reduce(function (map: any, item: any) {
          map[fieldBasedEnrollment ? item.field_id : item.property_id] = item;
          return map;
        }, {});

      const newCheckedListRaw = listToMap(res.property_data);
      setCheckedListRaw(Object.values(newCheckedListRaw));
    } else {
      console.log("PlanetAction query returned status code", res);
      setMessage("No data available for this UUID");
      setCheckedListProps({});
      setCheckedList([]);
      setCheckedListRaw([]);
    }
  });
};

export const changeCheckboxSelection = (
  checkedListProps: CheckedListProps,
  setCheckedListProps: any,
  enableCheckboxes: boolean
) => {
  console.log("checked list props object of objects", checkedListProps);

  const mapReplace = Object.values(checkedListProps).map(
    (item: CheckedListPropObject) => {
      console.log("this the actual object", item);
      item.isCheckboxEnabled = enableCheckboxes;
      return item;
    }
  );

  const listToMap = (list: any) =>
    list.reduce(function (map: any, item: any) {
      map[item.id] = item;
      return map;
    }, {});

  setCheckedListProps(listToMap(mapReplace));
};

export const validate = (args: ValidationObject) => {
  const {
    checkedListProps,
    setIsEverythingValid,
    setButtonText,
    setRequestMode,
    setButtonDisabled,
    setSelectionIndeterminate,
    setSelectAll,
  } = args;
  const filteredCheckedList = Object.values(checkedListProps).filter(
    (item: CheckedListPropObject) => {
      return item.isCheckboxEnabled;
    }
  );

  const requirePlanetValidation: any =
    filteredCheckedList.length !== 0 &&
    filteredCheckedList.every((item: CheckedListPropObject) => {
      return item.isCheckboxEnabled && !item.isPlanetEnabled && !!item.vertices;
    });

  const hasPlanetValidation: any =
    filteredCheckedList.length !== 0 &&
    filteredCheckedList.every((item: CheckedListPropObject) => {
      return item.isCheckboxEnabled && item.isPlanetEnabled && !!item.vertices;
    });

  const isSomethingSelected: any =
    filteredCheckedList.length !== 0 &&
    filteredCheckedList.every((item: CheckedListPropObject) => {
      return item.isCheckboxEnabled;
    });

  const isEverythingSelected: any =
    Object.values(checkedListProps).length === filteredCheckedList.length &&
    filteredCheckedList.length !== 0 &&
    filteredCheckedList.every((item: CheckedListPropObject) => {
      return item.isCheckboxEnabled;
    });

  setButtonDisabled(!isSomethingSelected);

  setSelectionIndeterminate(isSomethingSelected && !isEverythingSelected);
  setSelectAll(isEverythingSelected);

  const isReady = !!(requirePlanetValidation ^ hasPlanetValidation);
  let buttonActionText = "";

  if (requirePlanetValidation) {
    buttonActionText = "Enable";
    setRequestMode("ENABLE");
  } else if (hasPlanetValidation) {
    buttonActionText = "Disable";
    setRequestMode("DISABLE");
  } else {
    buttonActionText = "Enable";
    setRequestMode("ENABLE");
  }

  const buttonText = isReady ? buttonActionText : "Enable";
  setIsEverythingValid(isReady);
  setButtonText(buttonText);
};

export const submitHandler = (args: SubmitHandlerObject) => {
  const {
    requestMode,
    checkedListProps,
    checkedListRaw,
    fieldBasedEnrollment,
    setCheckedListRaw,
    dispatch,
  } = args;

  // Replace this to activate ENABLE on the requests when needed:
  // Leave it like VERIFY if on development
  const planetAccess = requestMode;
  //console.log("planetAccess would be ", requestMode)
  //const planetAccess = "VERIFY";

  const query = parseToRequiredDataObject(
    checkedListProps,
    fieldBasedEnrollment,
    planetAccess
  );

  dispatch(PlanetAction(query))
    .then((res: PlanetResponse) => {
      if (res) {
        const listToMap = (list: any) =>
          list.reduce(function (map: any, item: any) {
            map[fieldBasedEnrollment ? item.field_id : item.property_id] = item;
            return map;
          }, {});

        const newCheckedListRaw = {
          ...listToMap(checkedListRaw),
          ...listToMap(res.property_data),
        };
        setCheckedListRaw(Object.values(newCheckedListRaw));
        const result_sample: any = Object.values(newCheckedListRaw)[0]
        return result_sample.property_id;
      } else {
        console.log("PlanetAction query returned ", res);
      }
    }).then((property_id: string) => {
      const query = {
        properties_data: [{
          planet_access: planetAccess,
          activate_field_based_on_property: true,
          property_id: property_id
        }]
      };
      if (planetAccess == "ENABLE") {
        dispatch(PlanetAction(query));
      }
    }
    )
    .catch((err: any) => {
      console.log("catched an error", err);
    });
}