import { performLogin } from 'app-redux/actions/login.actions';
import { history, RoutePaths } from 'core/history';
import { INavigationRouteProps } from 'core/navigation/models';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SegmentService } from 'services/SegmentService';

interface ILoginProps extends INavigationRouteProps<{}> {
  performLogin: (code: string) => Promise<{}>;
}

const AuthenticationRedirect: React.FC<ILoginProps> = (props) => {
  const code = new URLSearchParams(props.location.search).get('code') || '';
  const state = new URLSearchParams(props.location.search).get('state');

  useEffect(() => {
    const loginAndRedirect = () => {
      return props.performLogin(code);
    };

    loginAndRedirect().then(() => {
      SegmentService().identify();
      if (state) {
        try {
          const newPath = atob(state);
          if (newPath.startsWith('/')) {
            history.replace(newPath);
          }
          history.replace(RoutePaths.PLANET_ACTIVATION());
        } catch (ex) {
          history.replace(RoutePaths.PLANET_ACTIVATION());
        }
      } else {
        history.replace(RoutePaths.PLANET_ACTIVATION());
      }
    }).catch();
  }, []);

  return (
        <div>
            Redirecting you to the application...
        </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => {
  return {
    performLogin: (code: string) => dispatch(performLogin(code)),
  };
};

export const AuthenticationRedirectComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthenticationRedirect));
