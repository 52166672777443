import { history } from 'core/history';
import { ThemeProvider } from 'core/theme';
import get from 'lodash/get';
import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router';
import styled from 'styled-components';

import './main.less';

import 'syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less';
import 'syngenta-digital-react-cropwise-assistant/dist/styles/cw-assistant.less';
import 'syngenta-digital-react-cropwise-deck-of-apps/dist/styles/cw-deck-of-apps.less';

export const StyledRoot = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;

const AsyncMainContainer = React.lazy(() => import('pages/main-container'));

export const App = () => {
  return (
        <ThemeProvider>
            <StyledRoot>
                <Router history={history}>
                    <Suspense fallback={<></>}>
                        <Switch>
                            <Route path="/" component={AsyncMainContainer} />
                        </Switch>
                    </Suspense>
                </Router>
            </StyledRoot>
        </ThemeProvider>
  );
};

export default App;
