import React, { useEffect, useState } from "react";
import "./JsonReader.less";

interface PropertyObject {
  setFile: (e: any) => void;
  file: any;
}

export const JsonReader: React.FC<PropertyObject> = ({
  setFile,
  file,
}): JSX.Element => {
  // TODO: Remove 'file' from the props is not needed and get the file
  // path for the uploading file

  // This will be used to write the file name once the file is uploaded
  const [fileName, setFileName] = useState("Select a file");
  const handler = (event: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "utf-8");

    fileReader.onload = (e) => {
      const parsedJson = JSON.parse(
        e.target?.result ? e.target?.result?.toString() : "",
      );
      setFile(parsedJson);
      setFileName("File selected");
    };
  };

  return (
    <label className="custom-file-input">
      <input
        id="input-file"
        accept="application/json"
        type="file"
        style={{ display: "none" }}
        onChange={handler}
      />
      <span className="selected-file">
        {fileName}
      </span>
    </label>
  );
};

export default JsonReader;
