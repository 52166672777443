import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const RoutePaths = {
  AUTH: () => '/authenticate',
  HOME: () => '/',
  PLANET_ACTIVATION: () => '/planet-activation',
};

export const PublicRoutes = [
  RoutePaths.AUTH(),
  RoutePaths.HOME(),
];
