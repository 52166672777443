import { createSelector } from "reselect";

import get from "lodash/get";
import qs from "querystring";
import { validate } from "uuid";
import { AccountsState } from "../reducers/accounts.reducer";
import { AppState } from "../reducers";

export const getAccountsState = (state: AppState) =>
  state.accounts || ({} as AccountsState);

export const getCurrentUser = createSelector(
  getAccountsState,
  (accountsState) => accountsState.currentUser
);
export const getInvitedAccount = createSelector(
  getAccountsState,
  (accountsState) => accountsState.invitedAccount
);
export const getCurrentUserLoadingStatus = createSelector(
  getAccountsState,
  (accountsState) => !!accountsState.loading
);

export const getCurrentWorkspaceFromURL = () => {
  const locationSearch = window.location.search.replace("?", "");
  const query = qs.parse(locationSearch);
  const qsWorkspace: any = query.workspace;
  if (validate(qsWorkspace)) {
    return qsWorkspace;
  }
  return null;
};

export const getCurrentWorkspace = (state: AppState) => {
  const workspaceFromURL = getCurrentWorkspaceFromURL();
  let workspaceFromExtendedAttribute;
  let firstAvailableWorkspace = undefined;
  if (
    state &&
    state.accounts &&
    state.accounts.currentUser &&
    state.accounts.currentUser.id
  ) {
    workspaceFromExtendedAttribute = get(
      state.accounts.currentUser.json_extended_attributes,
      "current_workspace"
    );
    firstAvailableWorkspace =
      state.accounts.currentUser.licensing_accounts.length &&
      state.accounts.currentUser.licensing_accounts.length < 5
        ? state.accounts.currentUser.licensing_accounts[0].licensing_account_id
        : undefined;
  }

  return (
    workspaceFromURL ||
    workspaceFromExtendedAttribute ||
    firstAvailableWorkspace
  );
};
