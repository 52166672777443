import { combineReducers } from 'redux';
import { accountsReducer, AccountsState } from './accounts.reducer';
import { OrgsState, orgsStateReducer } from './orgs.reducer';
import { PropertiesState, propertiesStateReducer } from './properties.reducer';

export interface AppState {
  accounts: AccountsState;
  orgsState: OrgsState;
  propertiesState: PropertiesState;
}

export const rootReducer = combineReducers<AppState>({
  accounts: accountsReducer,
  orgsState: orgsStateReducer,
  propertiesState: propertiesStateReducer,
});
