import {
  CheckedListPropObject,
} from "../interfaces/interfaces";

export const parseToQuery = (
  propertyID: string,
  fieldBasedEnrollment: boolean,
) => {

  const verification = fieldBasedEnrollment ? "VERIFY_FIELDS" : "VERIFY";

  return {
    properties_data: [
      {
        property_id: propertyID,
        planet_access: verification,
      }
    ],
  };
};

export const parseToRequiredDataObject = (
  checkedListProps: any,
  fieldBasedEnrollment: boolean,
  planetAccess: string = "VERIFY"
) => {
  const requiredData: any = {
    fields: undefined,
    property: undefined,
    mode: fieldBasedEnrollment ? "fields" : "property",
  };
  const listProps: CheckedListPropObject[] = Object.values(checkedListProps);
  const filtered = listProps.filter(
    (item: CheckedListPropObject) => item.isCheckboxEnabled
  );

  const body = (requiredData.fields = filtered.map(
    (item: CheckedListPropObject) => {
      const requestBody: any = {
        property_id: item.property_id,
        planet_access: planetAccess,
      };

      if (requiredData.mode === "fields") {
        requestBody.field_id = item.field_id;
      }

      if (item.mask && Object.keys(item.mask).length !== 0) {
        requestBody.simplified_geojson = item.mask;
      }

      return requestBody;
    }
  ));

  return {
    properties_data: body,
  };
};
