import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AssistantClient } from 'syngenta-digital-react-cropwise-assistant';
import { DeckOfAppsClient } from 'syngenta-digital-react-cropwise-deck-of-apps';
import MainApp from './App';
import { store } from './app-redux/store';
import { AppLoader } from './core/AppLoader';
import { getAccessToken } from './core/common-methods';

// replace console.* to disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const initClients = () => {
  const assistantClient = AssistantClient.getInstance();
  const deckOfAppsClient = DeckOfAppsClient.getInstance();

  let tokens;
  try {
    tokens = JSON.parse(getAccessToken());
  } catch (ignored) {
    tokens = null;
  }

  assistantClient.setBaseURL(`${process.env.API_URL}`);
  assistantClient.setDeepActionsURL(window.location.origin);
  deckOfAppsClient.setBaseURL(`${process.env.API_URL}`);

  if (tokens && tokens.access_token) {
    assistantClient.setToken(tokens.access_token);
    deckOfAppsClient.setToken(tokens.access_token);
  }
};

initClients();

(async function loadAppLoader() {
  const myapp = new AppLoader();
  const isRedirecting = await myapp.load();
  if (!isRedirecting) {
    ReactDOM.render((
                <Provider store={store}>
                    <MainApp />
                </Provider>
            ),
            document.getElementById('root'),
        );
  }
})();

ReactDOM.render(
    <div/>,
    document.getElementById('root'),
);
