import { resetCurrentOrg } from 'app-redux/actions/org.actions';
import { AppState } from 'app-redux/reducers';
import { getCurrentUser } from 'app-redux/selectors/accounts.selector';
import { IUserAccount } from 'core/accounts/models';
import { history, RoutePaths } from 'core/history';
import { INavigationRouteProps } from 'core/navigation/models';
import React, { ReactText } from 'react';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { Sidebar } from 'syngenta-digital-cropwise-react-ui-kit';
import { AppsIcon } from './images/AppsIcon';

import LeafImage from 'assets/images/cropwise-leaf.svg';
import { HomeIcon } from './images/HomeIcon';
import './index.less';
import {PuzzlesIcon} from './images/PuzzlesIcon';

interface IMenuProps extends INavigationRouteProps<{}> {
  currentUser?: IUserAccount;
  resetCurrentOrg?: () => Promise<string>;
}

interface IRouteTree {
  name: string;
  path: string;
  icon: JSX.Element;
}

const StyledLeaf = () => {
  return (
    <LeafImage/>
  );
};

export const MenuBar: React.FC<IMenuProps> = (props) => {

  const currentPath = props.location.pathname;

  const getCurrentOrgIdFromPath = (path: string) => {
    const pathParams = matchPath(path, {
          path: [
              '/orgs/:orgId/*',
          ],
          exact: true,
          strict: true,
      })?.params as { orgId?: string };

    return (pathParams && pathParams.orgId) ? pathParams.orgId : undefined;
  };

  const currentOrgId = getCurrentOrgIdFromPath(currentPath);

  const navigateTo = async (targetPath: ReactText, name: string) => {
    history.push(targetPath.toString());
  };

  const handleOnClick = async (name: any) => {
    if (name === 'Home' && props.resetCurrentOrg) {
      await props.resetCurrentOrg();
    }
  };

  const routesTree = [
    {
      name: 'Page Example',
      path: RoutePaths.PLANET_ACTIVATION(),
      icon: <AppsIcon />,
    }
  ];

  let showMenu = false;
  if (props.currentUser && currentOrgId) {
    showMenu = true;
  }

  return (
    showMenu ?

        (
            <Sidebar
                collapsedIcon={<StyledLeaf/>}
                expandedIcon={<StyledLeaf/>}
                theme="dark"
                selectedKeys={[props.location.pathname]}
                // @ts-ignore
                menuItems={
                  routesTree.map((menuItem: IRouteTree) => (
                  {
                    key: menuItem.path,
                    name: menuItem.name,
                    icon: menuItem.icon,
                    onClick: async (e: any) => {
                      handleOnClick(menuItem.name);
                      navigateTo(e.key, menuItem.name);
                    },
                  }
                  ))
                }
            />
        ) : <></>
  );

};

const mapStateToProps = (state: AppState, ownProps: IMenuProps): any => {
  return{
    ...ownProps,
    currentUser: getCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  resetCurrentOrg: () => dispatch(resetCurrentOrg()),
});

export const MenuBarComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuBar));
