import { Layout } from 'antd';
import { MenuBarComponent } from 'components/menu-component';
import { INavigationRouteProps } from 'core/navigation/models';
import React, { useMemo } from 'react';
import { MainRoutes } from 'routes';
import { ContextBar } from './ContextBar';
import { LoginSection } from './LoginSection';
import './style.less';

export const MainContainer: React.FC<INavigationRouteProps<{}>> = (props) => {

  const sidebarMemo = useMemo(() => (
        <MenuBarComponent/>
    ), [props.location.pathname]);

  return (
    <Layout className="cw-page-container">
        {sidebarMemo}
        <Layout>
          <ContextBar/>
          <div className="cw-login-nav-menu">
              <LoginSection/>
          </div>
          <MainRoutes/>
        </Layout>
    </Layout>
  );
};

export default MainContainer;
