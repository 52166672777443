// @ts-ignore
import Analytics from 'analytics-node';
import { AppState } from 'app-redux/reducers';
import { store } from 'app-redux/store';

interface ISegmentService {
  identify: () => void;
  track: (action: string) => void;
  page: (page: string) => void;
}

interface AnalyticsInfo {
  orgId: string;
  userRole: string;
}

interface IUserInfo {
  userId: string;
  name: string;
  role: string;
  email: string;
}

export const SegmentService = (): ISegmentService => {

  const analytics = new Analytics(process.env.SEGMENT_KEY);

  const state: AppState = store.getState();

  const userInfo: IUserInfo = {
    userId: state.accounts.currentUser ? state.accounts.currentUser.id : '',
    name: state.accounts.currentUser ? state.accounts.currentUser.name : '',
    role: state.accounts.currentUser ? state.accounts.currentUser.role : '',
    email: state.accounts.currentUser ? state.accounts.currentUser.email : '',
  };

  const getAnalyticsInfo = (): AnalyticsInfo => {
    const orgId = state.orgsState.currentOrgId ? state.orgsState.currentOrgId : '';
    return {
      orgId,
      userRole: userInfo.role,
    };
  };

  const analyticsData = getAnalyticsInfo();

  return {
    identify: () => {
      analytics.identify({
        userId: userInfo.userId ? userInfo.userId : 'Anonymous',
        traits: {
          ...userInfo,
        },
      });
    },

    page: (pageName) => {
      analytics.page({
        userId: userInfo.userId ? userInfo.userId : 'Anonymous',
        name: pageName,
        properties: {
          ...analyticsData,
        },
      });
    },

    track: (action) => {
      analytics.track({
        userId: userInfo.userId,
        event: action,
        properties: {
          ...analyticsData,
        },
      });
    },
  };
};
