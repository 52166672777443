import { AppState } from "app-redux/reducers";
import {
  getCurrentUser,
  getCurrentWorkspace,
} from "app-redux/selectors/accounts.selector";
import {
  getCurrentOrg,
  getOrgsMap,
  hasLoadedOrgs,
  selectIsLoadingOrgs,
} from "app-redux/selectors/orgs.selector";
import { IUserAccount } from "core/accounts/models";
import { INavigationRouteProps } from "core/navigation/models";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { matchPath, withRouter } from "react-router-dom";
import { TopNavBar } from "syngenta-digital-cropwise-react-ui-kit";

import {
  AssistantWrapper,
  AssistantLeafButton,
} from "syngenta-digital-react-cropwise-assistant";
import {
  DeckOfAppsClient,
  DeckOfAppsComponent,
} from "syngenta-digital-react-cropwise-deck-of-apps";
import { SegmentService } from "services/SegmentService";

import { history, RoutePaths } from "core/history";

import {
  fetchAllOrgs,
  setCurrentOrg,
} from "../../app-redux/actions/org.actions";
import { OrgMap } from "../../app-redux/reducers/orgs.reducer";
import { OrganizationDTO } from "../../core/orgs/models";
import { PageDTO, UUID } from "../../core/utils/BasicModels";

import LeafImage from "assets/images/cropwise-leaf.svg";
import "./style.less";

interface ContextBarProps extends INavigationRouteProps<{}> {
  currentOrg?: OrganizationDTO;
  currentUser?: IUserAccount;
  orgMap: OrgMap;
  isLoadingOrgs: boolean;
  hasLoadedOrgs: boolean;
  fetchOrgs: () => Promise<PageDTO<OrganizationDTO>>;
  setCurrentOrg: (orgId: UUID) => void;
  currentWorkspace: any;
}

export const ContextBarComponent: React.FC<ContextBarProps> = (props) => {
  const [storeAppsData, setStoreAppsData] = useState();
  const [cwContext, setCWContext] = useState({
    orgId: "",
    licensingAccountId: "",
  });
  const [assistantDrawerVisible, setAssistantDrawerVisible] = useState(false);

  const getCurrentOrgIdFromPath = (path: string) => {
    const pathParams = matchPath(path, {
      path: ["/orgs/:orgId/apps/:appId", "/orgs/:orgId/*"],
      exact: true,
      strict: true,
    })?.params as { orgId?: string };

    return pathParams && pathParams.orgId ? pathParams.orgId : undefined;
  };

  useEffect(() => {
    if (!props.hasLoadedOrgs && !props.isLoadingOrgs && props.fetchOrgs) {
      props.fetchOrgs().catch();
    }
  }, [props.hasLoadedOrgs, props.currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      const appsResponse = await DeckOfAppsClient.getInstance().getStoreApps();
      if (appsResponse) {
        setStoreAppsData(appsResponse);
      }
    };
    fetchData().catch();
  }, []);

  useEffect(() => {
    if (props.currentOrg && props.orgMap) {
      const orgList = Object.entries(props.orgMap);
      const org = orgList.find((o) => o[1].id === props.currentOrg?.id);
      setCWContext({
        orgId: props.currentOrg?.id,
        licensingAccountId: org ? org[1].licensing_account_id : "",
      });
    }
  }, [props.currentOrg, props.currentOrg]);

  const handleMenuClick = async (orgId: string) => {
    SegmentService().track(
      "Top Navbar - click on organizations menu to open an organization"
    );
    if (props.setCurrentOrg) {
      props.setCurrentOrg(orgId);
    }
    history.push(RoutePaths.PLANET_ACTIVATION());
  };

  const getContextBarItems = () => {
    const contextBarItems = [];
    const key: number = 0;

    const currentPath = props.location.pathname;
    const currentOrgId = getCurrentOrgIdFromPath(currentPath);

    if (props.currentOrg && currentOrgId) {
      const orgMenuList = [];
      for (const id in props.orgMap) {
        orgMenuList.push({
          key: id,
          name: props.orgMap[id].name,
          icon: "",
        });
      }

      const orgList = {
        key: props.currentOrg?.id,
        header: "Organization",
        name: props.currentOrg?.name,
        showRightNotch: true,
        groupName: "Organizations",
        onClickMenu: (id: any) => handleMenuClick(id || ""),
        goToLinkText: "Go to Organizations",
        goToLinkFunction: () => history.push(RoutePaths.PLANET_ACTIVATION()),
        menuName: "organization",
        subMenu: [...orgMenuList],
      };
      contextBarItems.push(orgList);
    }
    return contextBarItems;
  };

  const queryState = new URLSearchParams(location.search).get("state");
  let assistantState = {
    initialRoute: undefined,
    initialRouteState: undefined,
  };
  if (queryState) {
    try {
      const parsedState = JSON.parse(atob(queryState));
      if (parsedState.initialRoute) {
        assistantState = parsedState;
      }
    } catch (ignore) {
      /* do nothing. */
    }
  }

  return useMemo(() => {
    return (
      <>
        {assistantDrawerVisible && (
          <AssistantWrapper
            onClose={() => {
              setAssistantDrawerVisible(false);
            }}
            visible={assistantDrawerVisible}
            cwContext={cwContext}
            initialRoute={assistantState.initialRoute}
            initialRouteState={assistantState.initialRouteState}
          />
        )}
        <TopNavBar
          navbarIcon={() => (
            <div style={{ height: "34px", width: "34px" }}>
              <LeafImage />
            </div>
          )}
          newDesign={true}
          navbarUserInitials={props.currentUser?.name.charAt(0).toUpperCase()}
          contextBars={getContextBarItems()}
          assistant={
            <AssistantLeafButton
              cwContext={cwContext}
              onClick={() => setAssistantDrawerVisible(true)}
            />
          }
        />
      </>
    );
  }, [
    props.currentOrg,
    props.location.pathname,
    props.currentUser,
    props.isLoadingOrgs,
    assistantDrawerVisible,
    storeAppsData,
  ]);
};

const mapStateToProps = (
  state: AppState,
  ownProps: ContextBarProps
): ContextBarProps => {
  return {
    ...ownProps,
    currentOrg: getCurrentOrg(state),
    currentUser: getCurrentUser(state),
    orgMap: getOrgsMap(state),
    hasLoadedOrgs: hasLoadedOrgs(state),
    isLoadingOrgs: selectIsLoadingOrgs(state),
    currentWorkspace: getCurrentWorkspace(state),
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ContextBarProps
): ContextBarProps => {
  return {
    ...ownProps,
    fetchOrgs: () => dispatch(fetchAllOrgs()),
    setCurrentOrg: (orgId: UUID) => dispatch(setCurrentOrg(orgId)),
  };
};

export const ContextBar = withRouter(
  // @ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(ContextBarComponent)
) as unknown as React.FC<{}>;
