import { history, RoutePaths } from 'core/history';
import { APIFrontendComponent } from 'pages/api-frontend';
import { AuthenticationRedirectComponent } from 'pages/authentication-redirect-component';
import { PageNotFound } from 'pages/page-not-found';
import { RedirectIfToken } from 'pages/redirect-if-token';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

export const
  MainRoutes = () => {
    return (
      <Router history={history}>
          <Switch>
              <Route path={RoutePaths.AUTH()} exact={true} component={AuthenticationRedirectComponent} />
              <Route path={RoutePaths.PLANET_ACTIVATION()} exact={true} component={APIFrontendComponent} />
              <Route path={RoutePaths.HOME()} exact={true} component={RedirectIfToken} />
              <Route component={PageNotFound} />
          </Switch>
      </Router>
    );
  };

export default MainRoutes;
