import "./PlanetSearch.less";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SegmentService } from "services/SegmentService";

import {
  CheckedListProps,
  FakePlanetResponseObject,
  PlanetResponseHandler,
  PlanetResponseObject,
} from "../../interfaces/interfaces";
import { isValidUUID } from "../../functions/isValidUUID";
import parseResponse from "../../functions/parseResponse";
import {
  changeCheckboxSelection,
  createBaseCheckedListProps,
  onMatchUUID,
  submitHandler,
  validate,
} from "pages/api-frontend/functions/planetSearch";

export function usePlanetSearch() {
  const [propertyID, setPropertyID] = useState<string>("");
  const [fieldBasedEnrollment, setFieldBasedEnrollment] = useState(true);
  const [checkedListRaw, setCheckedListRaw] = useState<
    PlanetResponseObject[] | FakePlanetResponseObject[]
  >([]);
  const [checkedList, setCheckedList] = useState<PlanetResponseHandler[]>([]);
  const [checkedListProps, setCheckedListProps] = useState<CheckedListProps>(
    {}
  );
  const dispatch = useDispatch<any>();

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Verify");

  const [isEverythingValid, setIsEverythingValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [requestMode, setRequestMode] = useState("VERIFY");
  const [selectAll, setSelectAll] = useState(false);
  const [selectionIndeterminate, setSelectionIndeterminate] = useState(false);

  const resetState = () => {
    setMessage("");
    setIsEverythingValid(false);
    setCheckedListRaw([]);
    setCheckedList([]);
    resetCheckboxes();
  };

  const fetchProps = (
    id: string,
    isCheckboxEnabled_: any = null,
    mask_: any = null
  ) => {
    setCheckedListProps({
      ...checkedListProps,
      [id]: {
        ...checkedListProps[id],
        isCheckboxEnabled:
          isCheckboxEnabled_ !== null
            ? isCheckboxEnabled_
            : checkedListProps[id].isCheckboxEnabled,
        mask: mask_ !== null ? mask_ : checkedListProps[id].mask,
      },
    });
  };

  const resetCheckboxes = () => {
    setSelectionIndeterminate(false);
    setSelectAll(false);

    const newList = Object.values(checkedListProps).map((item) => {
      return {
        ...item,
        isCheckboxEnabled: false,
      };
    });

    const listToMap = (list: any) =>
      list.reduce(function (map: any, item: any) {
        map[fieldBasedEnrollment ? item.field_id : item.property_id] = item;
        return map;
      }, {});

    setCheckedListProps({ ...checkedListProps, ...listToMap(newList) });
  };

  const onManuallyChangeSelectAll = (e: any) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectionIndeterminate(false);

    changeCheckboxSelection(checkedListProps, setCheckedListProps, checked);
  };

  const onSubmit = async (values: any) => {
    resetState();
    setLoading(true);

    submitHandler({
      requestMode,
      checkedListProps,
      checkedListRaw,
      fieldBasedEnrollment,
      setCheckedListProps,
      setCheckedList,
      setCheckedListRaw,
      isEverythingValid,
      dispatch,
    });
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    resetState();
    if (isValidUUID(propertyID)) {
      setLoading(true);
      onMatchUUID({
        setMessage,
        setCheckedListProps,
        setCheckedListRaw,
        setCheckedList,
        propertyID,
        fieldBasedEnrollment,
        dispatch,
      });
    }
  }, [propertyID, fieldBasedEnrollment]);

  useEffect(() => {
    if (checkedListRaw.length !== 0) {
      const responseProps = parseResponse(checkedListRaw);
      setCheckedList(responseProps);
      createBaseCheckedListProps(responseProps, setCheckedListProps);
      setButtonDisabled(false);
    }
    setLoading(false);
  }, [checkedListRaw]);

  useEffect(() => {
    validate({
      checkedListProps,
      setIsEverythingValid,
      setButtonText,
      setRequestMode,
      setButtonDisabled,
      setSelectionIndeterminate,
      setSelectAll,
    });
  }, [checkedListProps]);

  useEffect(() => {
    SegmentService().page("Planet Activation");
  }, []);

  return {
    onSubmit,
    onSubmitFailed,
    propertyID,
    setPropertyID,
    fieldBasedEnrollment,
    setFieldBasedEnrollment,
    message,
    checkedListProps,
    setCheckedListProps,
    buttonDisabled,
    buttonText,
    loading,
    selectAll,
    selectionIndeterminate,
    onManuallyChangeSelectAll,
    fetchProps,
  };
}
