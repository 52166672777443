import React, { useEffect } from "react";
import "./CustomCheckbox.less";
import JsonReader from "../JSONReader/JsonReader";
import ReactTooltip from "react-tooltip";

export const CustomCheckbox: React.FC<any> = ({
  id,
  fetchProps,
  checkedListProps,
}): JSX.Element => {
  const {
    property_id,
    field_id,
    isCheckboxEnabled,
    name,
    area,
    vertices,
    result,
    mask,
    isValid,
    color,
  } = checkedListProps[id];

  const tooltip = (
    <ReactTooltip
      border={true}
      borderColor={color}
      type="light"
      place="right"
      effect="solid"
    />
  );

  // On heavy requests, the tooltip will freeze
  // Color isn't updating

  const colorDescription = (color: string) => {
    if (color === "#21f336") {
      return "Ready to be enabled.";
    } else if (color === "#00b5d8") {
      return "Already has planet.";
    } else if (color === "red") {
      return "Cannot be enabled. Please upload a mask.";
    } else if (color === "#ff0000") {
      return "Cannot be enabled.";
    } else {
      return "Initial state (not verified)";
    }
  };

  const setMaskHandler = (value: any) => {
    if (Object.values(value).length !== 0) {
      fetchProps(id, null, value);
    }
  };

  useEffect(() => {
    const id: string = field_id ? field_id : property_id;
    if (checkedListProps[id]) {
      fetchProps(id, null, checkedListProps[id].mask);
    }
  }, []);

  const style = isValid ? { display: "None" } : {};
  const area_text: string =
    Number(area) < 1000
      ? area + " ha"
      : Math.round((Number(area) / 1000) * 100) / 100 + "k ha";

  return (
    <div className="card-container">
      <div className="card-content">
        <div className="main">
          <div className="property">
            <div className="main-property">
              <div className="property-checkbox">
                <label className="container">
                  <input
                    type="checkbox"
                    onChange={() => {
                      fetchProps(id, !isCheckboxEnabled);
                    }}
                    checked={isCheckboxEnabled}
                  />
                  <span className="checkmark" />
                </label>
              </div>
              <div className="property-title">
                <div>
                  <div className="property-title-sup">{name}</div>
                  {area ? (
                    <div className="property-title-inf">{area_text}</div>
                  ) : null}
                </div>
              </div>
            </div>
            {vertices ? (
              <div className="property-vertices">Vertices: {vertices}</div>
            ) : null}
          </div>
          <div className="simplification-result" style={style}>
            <div className="simplification-data">
              <span>
                <b>{result?.mode} Simplification result</b>
              </span>
              <span>Area: {result?.area}k ha</span>
              <span>Vertices: {result?.vertices}</span>
              <span>Excess: {result?.excess}</span>
            </div>
            <div className="upload-mask">
              <div>
                <JsonReader setFile={setMaskHandler} file={mask} />
              </div>
            </div>
          </div>
        </div>
        <a
          className="color-code"
          style={{ background: color }}
          data-tip={colorDescription(color)}
        />
        {tooltip}
      </div>
    </div>
  );
};

export default CustomCheckbox;
