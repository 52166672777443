import { PageLayout } from 'components/page-layout';
import { RoutePaths } from 'core/history';
import React from 'react';
import { Redirect } from 'react-router';
import styled from 'styled-components';

const Label = styled.h1`
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 46px;
  color: rgb(46, 51, 125);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 50vh;
`;

export const RedirectIfToken = () => {

  return PageLayout({
    header: null,
    content: localStorage.getItem('tokens') ?
             (<Redirect to={RoutePaths.PLANET_ACTIVATION()}/>) :
             (
             <Container>
                 <Label>Please log in!</Label>
                 <p>
                     No credentials have been found.
                 </p>
             </Container>
             ),
  });
};
