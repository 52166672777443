import { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import { axiosRequest } from 'core/axios';
import { createRequestAction, RequestAction } from 'core/utils/actionUtils';
import { getHeaders } from 'core/common-methods';
import { PageDTO, UUID } from 'core/utils/BasicModels';

import { PropertyDTO } from '../../core/property/models';

export const ACTION_GET_PROPERTIES_LIST: RequestAction = createRequestAction('GET_PROPERTIES_LIST_REQUEST');
export const ACTION_GET_PROPERTY_GEOJSON_LIST: RequestAction = createRequestAction('ACTION_GET_PROPERTY_GEOJSON_LIST');

export const fetchAllProperties = (orgId: UUID) => async (dispatch: Dispatch) => {
  try {

    dispatch({ type: ACTION_GET_PROPERTIES_LIST.REQUEST });

    const allApps = await axiosRequest
      .get<any, AxiosResponse<PageDTO<PropertyDTO>>>(`/v2/orgs/${orgId}/properties`,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    const data = allApps.data.content;

    dispatch({ type: ACTION_GET_PROPERTIES_LIST.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_PROPERTIES_LIST.ERROR, payload: e });
    throw e;
  }
};

export const fetchPropertyGeoJSON = (propertyId: UUID) => async (dispatch: Dispatch) => {
  try {

    dispatch({ type: ACTION_GET_PROPERTY_GEOJSON_LIST.REQUEST });

    const allApps = await axiosRequest
      .get<any, AxiosResponse>(`/v2/properties/${propertyId}/fields/updates`,
      {
        headers: {
          ...getHeaders().common,
        },
      });

    const data = allApps.data;

    dispatch({ type: ACTION_GET_PROPERTY_GEOJSON_LIST.SUCCESS, payload: data });

    return data;
  } catch (e) {
    dispatch({ type: ACTION_GET_PROPERTY_GEOJSON_LIST.ERROR, payload: e });
    throw e;
  }
};
